import React, { useState } from "react";
import ReactTable from "../ReactTables/ReactTable";
import { autoServerTableColumns } from "../../utils/reactTableColumns";
import { getErrors, hasData, toastDefault } from "../../utils/handler.utils";
import { Link } from "react-router-dom";
import useGet from "../../hooks/useGet";
import UiHandler from "../UiHandler";
import { toast } from "react-toastify";
import axiosInstance from "../../common/axios";

function TopupProduct() {
  const [uniqueState, setUniqueState] = useState(false);

  const [serverData, loading, error] = useGet(
    `admin/auto-server`,
    "",
    uniqueState
  );

  const deleteServerHangdler = (id) => {
    if (window.confirm("Are you sure")) {
      toast.promise(
        axiosInstance.post(`admin/auto-server/delete/${id}`),
        {
          pending: "Deleting Server...",
          error: {
            render(err) {
              console.log(err);
              return getErrors(err.data, false, true);
            },
          },
          success: {
            render() {
              setUniqueState((prev) => !prev);
              return "Server deleted successfully";
            },
          },
        },
        toastDefault
      );
    }
  };

  let editButton = {
    id: "action",
    Header: "Action",
    accessor: "id",
    Cell: (e) => {
      return (
        <ul className="flex space-x-2">
          <Link to={`/auto-server/edit/${e.value}`} className="cstm_btn_small">
            Edit
          </Link>
          <button
            className="cstm_btn_small !bg-red-600 hover:!bg-red-700"
            type="button"
            onClick={() => deleteServerHangdler(e.value)}
          >
            Delete
          </button>
        </ul>
      );
    },
  };

  let withEditButton = [...autoServerTableColumns, editButton];

  return (
    <section className="relative container_admin">
      <div className="bg-white overflow-hidden rounded">
        <div className="px-6 py-3 border-b border-gray-200 flex items-center justify-between flex-wrap">
          <h3 className="text-lg font-bold text-black">Server</h3>
          <Link className="cstm_btn" to="/auto-server/add">
            Add new
          </Link>
        </div>
        <div>
          <div className="relative min-h-[100px]">
            <UiHandler data={serverData} loading={loading} error={error} />
            {hasData(serverData, error) && (
              <ReactTable
                tableId="product_methods_table"
                columns={withEditButton}
                data={serverData}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopupProduct;
