import React from 'react'
import ProfileLayout from './ProfileLayout'

function Profile() {
    return (
        <ProfileLayout>
            Profile
        </ProfileLayout>
    )
}

export default Profile
