import React, { useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../common/axios";
import { getErrors, toastDefault } from "../../utils/handler.utils";
import Loader from "../Loader/Loader";

function AddTopupProduct() {
  const nameRef = useRef(null);
  const linkRef = useRef(null);
  const statusRef = useRef(null);

  const [loading, setLoading] = useState(null);
  const history = useHistory();

  const createProductHandler = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      axiosInstance
        .post("/admin/auto-server/create", {
          name: nameRef.current.value,
          link: linkRef.current.value,
          status: statusRef.current.value,
        })
        .then((res) => {
          toast.success("Server created successfully", toastDefault);

          setTimeout(() => {
            history.push("/auto-server");
          }, 1500);
        })
        .catch((err) => {
          toast.error(getErrors(err, false, true), toastDefault);
          setLoading(false);
        });
    }
  };

  return (
    <section className="relative container_admin">
      <div className="bg-white overflow-hidden rounded">
        <div className="px-6 py-3 border-b border-gray-200">
          <h3 className="text-lg font-bold text-black">Create new server</h3>
        </div>
        <div className="py-10 px-4">
          <div className="w-full md:w-[70%] mx-auto py-6 relative border border-gray-200 px-4">
            {loading && <Loader absolute />}
            <form onSubmit={createProductHandler}>
              <div>
                <div className="form_grid">
                  <div>
                    <label htmlFor="name">Name</label>
                    <input
                      ref={nameRef}
                      id="name"
                      className="form_input"
                      type="text"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="link">Link</label>
                    <input
                      ref={linkRef}
                      id="link"
                      className="form_input"
                      type="text"
                      placeholder="Link"
                      required
                    />
                  </div>
                </div>
                <div className="my-2">
                  <div class="relative">
                    <select
                      ref={statusRef}
                      id="type"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="cstm_btn w-full block"
                  >
                    Create Product
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddTopupProduct;
